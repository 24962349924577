.home-screen {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 2rem;
    text-align: center;
    background-image: url('https://babypaal.com/wp-content/uploads/2024/11/Coming-Soon.png');
    /* Sample image URL */
    background-size: cover;
    background-position: center;
}